// Vendor Files
import React from "react";
import { Form, Modal, Alert, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class RSVPEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rsvpSource: '',
            rsvpPreferredDay: "",
            rsvpAttendees: "",
            rsvpFirstName: "",
            rsvplastName: "",
            rsvpEmail: "",
            rsvpPhone: "",
            rsvpZipcode: "",
            rsvpAddress: "",
            rsvpShowFormWarning: false,
            rsvpWarningText: "",
            rsvpDisableRegisterBtn: false,
            rsvpShowSuccessMsg: false,
        };
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get('source')) {
            this.setState({rsvpSource: params.get('source').toLocaleLowerCase()});
        }
    }

    handleClose() {
        this.props.hideForm();
    }

    handleRSVPRegister() {
        // Check fields are filled
        let unfilledFields = this.state.rsvpPreferredDay === "" || this.state.rsvpAttendees === "" || this.state.rsvpEmail === "";
        if (unfilledFields === true) {
            this.setState({ rsvpWarningText: "Please fill out the required fields.", rsvpShowFormWarning: true });
            return;
        }

        // Check email is in correct format
        if (!this.validateEmail(this.state.rsvpEmail)) {
            this.setState({ rsvpWarningText: "Please enter a valid email.", rsvpShowFormWarning: true });
            return;
        }

        // let url = "http://localhost:5004/api/rsvp/" + this.props.formData.eventName // Dev testing
        let url = "https://mw-chicago-mandir-backend.web.app/api/rsvp/" + this.props.formData.eventName // Production
        let body = {
            event: this.props.formData.eventName,
            eventDisplayName: this.props.formData.displayName,
            prefferedDay: this.state.rsvpPreferredDay,
            attendees: this.state.rsvpAttendees,
            email: this.state.rsvpEmail,
            firstName: this.state.rsvpFirstName,
            lastName: this.state.rsvpLastName,
            phone: this.state.rsvpPhone,
            zipcode: this.state.rsvpZipcode,
            address: this.state.rsvpAddress,
            source: this.state.rsvpSource,
            eventAddress: this.props.formData.location
        }
        this.setState({ rsvpDisableRegisterBtn: true });
        axios.post(url, body).then((res) => {
            if (res.data.data.affectedRows > 0) {
                this.setState({ rsvpDisableRegisterBtn: false, rsvpShowSuccessMsg: true, rsvpShowFormWarning: false });
                this.handleResetFormFields();
            }
            else {
                this.setState({ rsvpDisableRegisterBtn: false });
                this.setState({ rsvpWarningText: "An error occured while sending your information. Please try again.", rsvpShowFormWarning: true });
            }
        });
    }

    handleResetFormFields() {
        this.setState({
            rsvpPreferredDay: "",
            rsvpAttendees: "",
            rsvpFirstName: "",
            rsvpLastName: "",
            rsvpEmail: "",
            rsvpPhone: "",
            rsvpZipcode: "",
            rsvpAddress: ""
        });
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    render() {
        return (
            <Modal show={true} onHide={() => this.handleClose()} size="lg" className="modalCont" scrollable={true}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="modalHeader">
                        <h2>{this.props.formData.displayName}</h2>
                        <p>
                            <b>Location:</b> {this.props.formData.location}
                        </p>
                        <p>{this.props.formData.description}</p>
                    </div>

                    {this.state.rsvpShowFormWarning ? <Alert variant={"danger"}>{this.state.rsvpWarningText}</Alert> : ""}
                    {this.state.rsvpShowSuccessMsg ? <Alert variant={"success"}>We have successfully registered you for the event, please check your email for more details.</Alert> : ""}
                    {!this.state.rsvpShowSuccessMsg &&
                        <Form autoComplete="disabled">
                            <Form.Group className="mb-3">
                                <Form.Label>Select which day you will attend:<sup>*</sup></Form.Label>
                                <Form.Control as="select" value={this.state.rsvpPreferredDay} onChange={(event) => this.setState({ rsvpPreferredDay: event.target.value })}>
                                    <option className="hideOption"></option>
                                    <option disabled>Pick and day and time</option>
                                    {this.props.formData.eventDays.map(eventDay => {
                                        return (
                                            <option key={eventDay}>{eventDay}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label># of Guests Attending:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="number"
                                    max="99"
                                    pattern="[0-9]*"
                                    placeholder="Enter number of guests attending"
                                    value={this.state.rsvpAttendees}
                                    onChange={(event) => this.setState({ rsvpAttendees: event.target.value.substring(0,2) })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email:<sup>*</sup></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={this.state.rsvpEmail}
                                    onChange={(event) => this.setState({ rsvpEmail: event.target.value })}
                                />
                            </Form.Group>
                            <Container>
                                <Row className="nameCont">
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Firstname:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your first name"
                                                name="fname"
                                                value={this.state.rsvpFirstName}
                                                onChange={(event) => this.setState({ rsvpFirstName: event.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Lastname:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your last name"
                                                name="lname"
                                                value={this.state.rsvpLastName}
                                                onChange={(event) => this.setState({ rsvpLastName: event.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                            <Form.Group className="mb-3">
                                <Form.Label>Cell Phone:</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter your phone number"
                                    name="phone"
                                    value={this.state.rsvpPhone}
                                    onChange={(event) => this.setState({ rsvpPhone: event.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Zipcode:</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter your zipcode"
                                    value={this.state.rsvpZipcode}
                                    onChange={(event) => this.setState({ rsvpZipcode: event.target.value })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <br />
                                <p>If you would like to receive mailings or further communications from the BAPS Mandir please provide additional information&nbsp;below.</p>
                                <Form.Label>Address:</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your address"
                                    name="address"
                                    value={this.state.rsvpAddress}
                                    onChange={(event) => this.setState({ rsvpAddress: event.target.value })}
                                />
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" className="mr-auto" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                    {!this.state.rsvpShowSuccessMsg &&
                        <div>
                            <Button variant="primary" onClick={() => this.handleRSVPRegister()} disabled={this.state.rsvpDisableRegisterBtn}>
                                Register Now
                            </Button>
                        </div>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withParams(RSVPEvent);